import { ethers } from 'ethers'
import { type IOptionInfo } from '@/components/business/deorder/option-info'

export const useDeOrderDialogStore = defineStore('deorderDialog', () => {
  const DEFAULT_DATA: IOptionInfo = {
    type: 'BUY',
    strikePrice: '0',
    exerciseTimestamp: Math.floor(Date.now() / 1000).toString(),
    address: ethers.ZeroAddress,
    apr: '0',
    token: 'uSNIPER',
    staked: {
      amount: '0',
      amountUSD: '0',
    },
    tvl: {
      amount: '0',
      amountUSD: '0',
    },
  }
  const {
    value: isDialogVisible,
    setTrue: openDialog,
    setFalse: closeDialog,
  } = useBoolean(false)
  const data = ref<IOptionInfo>(DEFAULT_DATA)

  const setData = (newData: IOptionInfo) => {
    data.value = newData
  }

  const reset = () => {
    data.value = DEFAULT_DATA
  }

  return {
    data,
    isDialogVisible,
    openDialog,
    closeDialog,
    setData,
    reset,
  }
})
